export default {
  "login": {
    "iniciaSesion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login"])},
    "ingresadatos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter your registration information to access"])},
    "correo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
    "contra": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password"])},
    "iniciarSesion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login"])},
    "registrate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign up"])},
    "recuperarcontra": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Did you forget your password?"])},
    "alertmsg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wrong email or password"])}
  },
  "singup": {
    "registrate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Register"])},
    "ingresadatos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter your information to complete the registration"])},
    "nombre": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
    "apellido": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last name"])},
    "telefono": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone"])},
    "direccion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Address"])},
    "sexo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gender"])},
    "correo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
    "contra": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password"])},
    "confirmcontra": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm Password"])},
    "selecperfil": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profile Selection"])},
    "fechanacimiento": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date of birth"])},
    "curso": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grade"])},
    "colegio1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["School 1"])},
    "colegio2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["School 2"])},
    "crearcuenta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create account"])},
    "iniciaSesion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login"])},
    "profesor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Professor"])},
    "investigador": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Researcher"])}
  },
  "forgotpassword": {
    "recuperarcontra": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recover password"])},
    "ingrescorreop1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter the registered email in"])},
    "ingresacorreop2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["+MEANING"])},
    "ingresacorreop3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" to send the recovery instructions"])},
    "correo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
    "enviar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send instructions"])},
    "alertmsg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Request a new password from aev", "@", "meaning.cl and we will send it to you as soon as possible."])}
  },
  "recoverpassword": {
    "nuevacontratitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New password"])},
    "ingresanuevacontra": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter a new password for your account"])},
    "nuevacontra": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New password"])},
    "confirmarnuevacontra": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm new password"])},
    "cambiarcontra": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change password"])},
    "alertchangepass": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your password has been changed successfully"])},
    "linkcaducado": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The link to recover password has expired"])}
  },
  "sidenav": {
    "inicio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Home"])},
    "administradores": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["administrators"])},
    "profesores": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teachers"])},
    "investigadores": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Researchers"])},
    "seguimiento": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activity monitoring"])},
    "actividadesasignadas": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assigned activities"])},
    "bancopreguntas": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Question bank"])},
    "actividades": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activities"])},
    "descarga": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download responses"])},
    "masinfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["More Information"])},
    "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Log out"])},
    "salir": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exit"])}
  },
  "adminhome": {
    "saludo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hello"])},
    "bienvenida": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Welcome to the control center"])}
  },
  "adminlist": {
    "breadcrumb": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["administrators"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["administrators"])},
    "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add or manage platform administrators"])},
    "btnoptions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Options"])},
    "linkedit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit administrator"])},
    "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete administrator"])},
    "pendinginvitation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pending invitations"])},
    "resendinvitation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resend invitation"])},
    "deleteinvitation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete invitation"])},
    "nodelete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This user cannot be deleted"])},
    "successmsg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invitation successfully forwarded"])},
    "confirmmsg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure you want to delete this invitation?"])},
    "deleteuser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure you want to delete this user?"])}
  },
  "adminedit": {
    "breadcrumb": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["administrators"])},
    "breadcrumbnavitem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit"])},
    "titleedit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit"])},
    "labelname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
    "labellastname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last Name"])},
    "btnupdate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update administrator"])},
    "successmsg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Administrator was updated"])}
  },
  "admininvite": {
    "breadcrumb": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["administrator"])},
    "breadcrumbnavitem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invite"])},
    "titleedit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invite administrator"])},
    "labelemail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
    "btninvite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invite administrator"])},
    "successmsg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Administrator was invited"])}
  },
  "teacherlist": {
    "breadcrumb": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teachers"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teachers"])},
    "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manage the teachers of the platform"])},
    "btnoptions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Options"])},
    "linkedit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View teacher"])},
    "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete teacher"])},
    "disablesprofesor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disabled Teachers"])},
    "rehabilitarprofesor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enable Teacher"])},
    "eliminaralert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure you want to remove this user?"])},
    "rehabilitaralert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure you want to re-enable this user?"])}
  },
  "teachershow": {
    "breadcrumb": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teachers"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teacher"])},
    "info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Information"])},
    "correo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
    "nombre": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
    "sexo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sex"])},
    "fechanacimiento": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date of birth"])},
    "telefono": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone"])},
    "direccion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Address"])},
    "curso": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grade"])},
    "colegio1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["School 1"])},
    "colegio2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["School 2"])}
  },
  "researcherlist": {
    "breadcrumb": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Researchers"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Researchers"])},
    "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manage the researchers of the platform "])},
    "btnoptions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Options"])},
    "linkedit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View researcher"])},
    "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete researcher"])},
    "disablesprofesor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disabled researchers"])},
    "rehabilitarprofesor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enable researcher"])},
    "eliminaralert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure you want to remove this user?"])},
    "rehabilitaralert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure you want to re-enable this user?"])}
  },
  "researchershow": {
    "breadcrumb": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Researchers"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Researcher"])},
    "info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Information"])},
    "correo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
    "nombre": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
    "sexo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sex"])}
  },
  "questionsbank": {
    "breadcrumb": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Question bank"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Question bank"])},
    "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["List of questions available for creating activities "])},
    "btndelete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete"])},
    "deletedesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You are about to delete this question."])},
    "questiondelete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure you want to continue?"])},
    "confirmdelete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yes delete"])},
    "canceldelete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])}
  },
  "newquestion": {
    "breadcrumb": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Question bank"])},
    "breadcrumb2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New question"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NEW QUESTION"])},
    "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Complete the following information and create a new question"])},
    "btnfinish": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create question"])},
    "alertmsg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your question has been created successfully"])}
  },
  "activities": {
    "breadcrumb": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activities"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ACTIVITIES"])},
    "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Find all activities created in"])},
    "btnpublic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Public activities"])},
    "btnprivate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Private activities"])},
    "btnassigned": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assigned Activities"])},
    "btndefault": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Default activities"])}
  },
  "activityshow": {
    "nivel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grade"])},
    "asignatura": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subject"])},
    "eje": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AXIS"])},
    "por": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BY"])},
    "actividades": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activities"])},
    "preguntas": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Questions"])},
    "sendresp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Submit my answer(s)"])},
    "finishacti": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Finish activity"])},
    "aboutacti": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["About activity"])},
    "enlace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Link"])},
    "btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Options"])},
    "deactactivi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deactivate activity"])},
    "editactivi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit activity"])},
    "deltactivi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete activity"])},
    "recursosPrint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Here is printable material for your class"])},
    "recursosRecom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lesson plan"])},
    "enlaceApod": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Link to material for the carer"])},
    "docenteinteractiva": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Interactive lesson plan"])},
    "deletedesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You are about to delete this activity and all its access codes"])},
    "questiondel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure you want to continue?"])},
    "confirmdelete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yes delete"])},
    "canceldelete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
    "respenviadas": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Submitted responses"])},
    "respdesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your answers have been sent"])},
    "respconfirmsend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Congratulations! your activity is now complete and your answers have been sent correctly."])},
    "salir": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exit"])},
    "continueactiv": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continue in the activity"])},
    "deactivateactiv": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deactivate"])},
    "deactivateactivdesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You are about to deactivate this activity"])},
    "deactivateactivdescquest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure you want to continue?"])},
    "deactivateconfirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yes deactivate"])},
    "fullscreendesktopone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["For a better experience select "])},
    "fullscreendesktoptwo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Then select "])},
    "fullscreenphone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["For a better experience select"])},
    "objetivosDeAprendizaje": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Learning objective(s)"])},
    "objetivoDeDesarrolloSocioemocional": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Socio-emotional development objective(s)"])},
    "activacion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["activation"])},
    "desde": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["From the day"])},
    "hasta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Until the day"])},
    "ilimitada": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unlimited"])},
    "editarActivacion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit activation"])},
    "editarActivacionDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You are about to edit activation. For this, first select your enabling time"])},
    "activityCompletedButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I completed the activity"])},
    "duplicarActividad": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Duplicate activity"])},
    "successmsg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activity has been duplicated"])},
    "hazclickaqui": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Click here"])},
    "verPdf": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View pdf"])}
  },
  "activitiesedit": {
    "breadcrumb": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activities"])},
    "breadcrumbnavitem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit activity"])},
    "titleedit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["EDIT ACTIVITY"])},
    "subtitleedit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modify your activity"])},
    "btnupdate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update activity"])},
    "successmsg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activity was modified"])}
  },
  "activitiesnew": {
    "breadcrumb": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activities"])},
    "breadcrumbnavitem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New activity"])},
    "titlecreate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NEW ACTIVITY"])},
    "subtitlecreate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Complete the following information and create a new activity"])},
    "btncreate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create Activity"])},
    "successmsg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activity was created"])}
  },
  "accessactivity": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Welcome"])},
    "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To access the activity enter your name and the activity code"])},
    "activityname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
    "activitycode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Code"])},
    "enteractivity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter the activity"])},
    "codnodis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["code not available"])},
    "caducatecode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The code you entered has expired or is no longer available."])},
    "entendido": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OK"])},
    "entername": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You just have to enter your name to go to the activity"])}
  },
  "downloaddata": {
    "breadcrumb": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download answers"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download answers"])},
    "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download the complete list of answers associated with the questions used in the activities. This process may take time depending on the amount of data stored."])},
    "btndownload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DOWNLOAD ANSWERS"])},
    "tableStatement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The downloaded file contains the next columns: "])},
    "column": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Column"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description"])},
    "question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pregunta"])},
    "answer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Respuesta"])},
    "answerType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipo de respuesta"])},
    "dateofanswer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fecha de respuesta"])},
    "activity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actividad"])},
    "activityID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID de la actividad"])},
    "eventCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Código de evento"])},
    "course": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Curso"])},
    "axis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eje curricular"])},
    "professorActivity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profesor (a) Actividad"])},
    "teacherId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID Profesor"])},
    "school1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Colegio 1 Profesor(a)"])},
    "school2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Colegio 2 Profesor(a)"])},
    "authorQuestion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autor pregunta"])},
    "descriptionQuestion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pregunta respondida por el alumno."])},
    "descriptionAnswer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Respuesta entregada por el alumno."])},
    "descriptionAnswerType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Puede ser “Abierta”, “Selección única” o “Selección múltiple”."])},
    "descriptionDateofanswer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fecha y hora en la que el alumno entregó la respuesta."])},
    "descriptionActivity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre de la actividad asignada."])},
    "descriptionActivityID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identificador numérico único de la actividad."])},
    "descriptionEventCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Código del evento de la actividad, este código es único."])},
    "descriptionCourse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Curso al que se le asignó la actividad."])},
    "descriptionAxis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contenidos tratados en la actividad."])},
    "descriptionProfessorActivity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre del profesor que creó la actividad."])},
    "descriptionTeacherId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identificador numérico único del profesor que creó la actividad."])},
    "descriptionSchool1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre del colegio al que pertenece el profesor que creó la actividad."])},
    "descriptionSchool2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre del colegio número dos al que pertenece el profesor que creó la actividad. Si este campo se encuentra vacío se debe a que el profesor no presta servicios a un segundo colegio."])},
    "descriptionAuthorQuestion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre del profesor que creó la pregunta."])}
  },
  "activitiesform": {
    "curso": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grade"])},
    "asignatura": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subject"])},
    "eje": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Axis"])},
    "idioma": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Language"])},
    "visibilityactiv": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["activity visibility"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Title"])},
    "Instruccion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Instruction"])},
    "caption1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add the instructions to your activity. You can format them in the following box."])},
    "caption2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remember that the maximum weight for images is 5mb."])},
    "enlacePrint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Link to printable material"])},
    "enlaceRecom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Link to downloadable lesson plan"])},
    "enlaceApod": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Link to material for the carer"])},
    "enlaceInteractiva": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Link to interactive lesson plan"])},
    "questionaddactivity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Questions added to the activity"])},
    "addquestion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add question"])},
    "questionbank": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Question bank"])},
    "btnadd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add"])},
    "btncontinuar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continue"])},
    "addslide": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add slide"])},
    "herramientacreacion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tool to create activity"])},
    "manual": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manual"])},
    "genially": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Genially"])},
    "linkgenially": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter the Genially link"])},
    "objetivosDeAprendizaje": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Learning objective(s)"])},
    "objetivoDeDesarrolloSocioemocional": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Socio-emotional development objective(s)"])},
    "actividadDefault": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Default activity"])}
  },
  "activitycard": {
    "activa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Active"])},
    "info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Share"])},
    "activar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Share"])},
    "ver": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You are about to activate this activity. For this, first select your enabling time"])},
    "timehabili": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enable time"])},
    "desde": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["From the day"])},
    "hasta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Until the day"])},
    "cerrar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Close"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Share"])},
    "infodisp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your activity is available on a limited basis between days"])},
    "al": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["to"])},
    "unlimiteddisp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your activity is available unlimited"])},
    "copylink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copy the following link to share this activity"])},
    "copylinksecond": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Or copy the following link to access any activity"])},
    "uniccode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Share the following unique code to enter this activity using the link above"])},
    "viewactivity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View activity"])},
    "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Link:"])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Code:"])},
    "assignment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assignment"])},
    "activityassignment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activity assignment"])},
    "assign": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To assign a teacher to the activity his box must be checked"])},
    "unassign": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To unassign a teacher from the activity their box must be unchecked"])},
    "updateassignment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update assignment"])},
    "selectall": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select all"])}
  },
  "questionform": {
    "encabezado": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Question header"])},
    "responsetype": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Response type"])},
    "Opcion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Option"])}
  },
  "invitesshow": {
    "register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign up"])},
    "enterdata": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter your information to complete the registration"])},
    "correo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
    "nombre": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
    "apellido": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last name"])},
    "contra": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password"])},
    "confirmcontra": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm Password"])},
    "registrar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Register"])},
    "alertmsg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This invitation has expired"])}
  },
  "teacherhome": {
    "saludo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hello"])},
    "bienvenida": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Welcome to"])}
  },
  "moreinfo": {
    "masinfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["For more information visit"])}
  },
  "activityprogresstracker": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activity Progress Tracker"])},
    "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Find the activities you have shared and the number of students who have compiled them."])},
    "breadcrumb": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activity progress tracker"])},
    "titleActivity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Title of the activity"])},
    "idActivity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activity identifier"])},
    "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activation date"])},
    "isActive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
    "number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number of students who have completed the activity"])},
    "searchOptions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search options"])},
    "respuestas": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number of responses"])},
    "Total_responses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total number of responses"])}
  },
  "Assignedactivities": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assigned Activities"])},
    "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In this section, you can massively assign teachers to the activities of your choice by uploading a CSV file. In addition, you will find a complete list of activities accompanied by their respective assigned teachers."])},
    "breadcrumb": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assigned Activities"])},
    "titleActivity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Title of the activity"])},
    "idActivity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activity identifier"])},
    "teacher": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assigned teachers"])},
    "searchOptions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search options"])},
    "downloadTeachers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download list of Teachers"])},
    "downloadActivities": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download list of Activities"])},
    "downloadTemplate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download Template"])},
    "uploadCsv": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assign activities via a CSV file"])},
    "successMsg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All assignments were successfully completed."])},
    "downloadErrors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["here"])},
    "downloadErrorsMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unable to complete all assignments. For more information, click"])}
  },
  "activityprogresstrackerCard": {
    "activa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Active"])},
    "inactiva": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inactive"])}
  }
}