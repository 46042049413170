<template>
  <div :class="['layout', (($store.state.user.id && !$route.meta.noSideNav) || $route.meta.isTutor) ? '' : 'layout--out']" >
    <div class="content-wrapper">
      <side-nav v-if="$store.state.user.id && !$route.meta.noSideNav" v-model="showSideNav"/>      
      <router-view/>
      <div class="top-nav" v-if="!showSideNav">
        <div class="top-nav__side top-nav__side--left" @click.prevent="showSideNav = !showSideNav">
          <img class="top-nav__icon" src="@/assets/images/icons/ico-menu.svg">
        </div>
        <div class="top-nav__side top-nav__side--center"><img class="top-nav__logo" src="@/assets/images/logos/LogoMeaningHorizontal.svg"></div>
      </div>
    </div>
  </div>
</template>

<script>
import SideNav from '@/components/SideNav'

export default {
  data () {
    return {
      expandedSideNav: (this.$store.state.user.ui_data || {})['EXPANDED-SIDEBAR'],
      persistingData: {
        sideNavigation: false
      },
    }
  },
  components: { SideNav },
  methods: {
  },
  computed: {
    showSideNav: {
      get () {
        
        return this.$route.meta.isTutor ? this.$store.state.tutorSideNavExpanded : this.expandedSideNav
      },
      async set (value) {
        if (this.$route.meta.isTutor) {
          this.$store.commit('setTutorSideNavExpanded', value)
        } else {
          if (!this.persistingData.sideNavigation) {
            this.persistingData.sideNavigation = true
            this.expandedSideNav = value
            if (this.$store.state.user.id) {
              let user = (await this.$axios.patch(`users/${this.$store.state.user.id}`, { ui_data: { 'EXPANDED-SIDEBAR': value } })).data.user
              this.$store.commit('setUserData', user)
            }
            this.persistingData.sideNavigation = false
          }
        }
      }
    }
  },
  watch: {
    $mq () {
      this.$store.commit('setMediaQuery', this.$mq)
      /* if (this.$store.state.mediaQuery === 'xl') {
        this.expandedSideNav = this.expandedSideNav === undefined ? true : this.expandedSideNav
      } else {
        this.expandedSideNav = false
      } */
      if (this.showSideNav && !['xl', 'xxl'].includes(this.$mq)) {
        this.showSideNav = false
      }
      if (!this.showSideNav && ['xl', 'xxl'].includes(this.$mq)) {
        this.showSideNav = true
      }
    },
    '$store.state.user' () {
      this.expandedSideNav = (this.$store.state.user.ui_data || {})['EXPANDED-SIDEBAR']
    }
  }
}
</script>

<style lang="sass">
  @use '@/assets/styles/main.sass'
</style>
