<template>
<div class="switcher-content">
    <div class="flag-content">
        <div :style="$i18n.locale == 'es' ? SelectedStyle : NoSelectedStyle" >
            <flag class="flag" iso="es" />
        </div>

        <div :style="$i18n.locale == 'en' ? SelectedStyle : NoSelectedStyle">
            <flag class="flag" iso="us" />
        </div>
    </div>
    <div v-if="$i18n.locale !== 'es'" class="switch-component-wrapper" @click="changeSwitchValue; changeLanguage('es');">
        <div class="switch-wrapper" :class="{'off':switchValue, 'on': !switchValue}">
            <div class="circle">
                    
            </div>
                
        </div>
            
    </div>
    <div v-else-if="$i18n.locale !== 'en'" class="switch-component-wrapper" @click="changeSwitchValue; changeLanguage('en');">
        <div class="switch-wrapper" :class="{'on':switchValue, 'off': !switchValue}">
            <div class="circle">
                    
            </div>
                
        </div>
            
    </div>
</div>
</template>

<script>
export default {
 props: ["switchValue"],
 methods : {
     changeSwitchValue(){
         this.$emit('switchValueChanged');
     },
     changeLanguage(lang) {
      this.$i18n.locale = lang
    },

 },
 data() {
    return {
        languages: [
            {language: 'en', title: 'English' },
            {language: 'es', title: 'Español' }
        ],
        show:true,
        
        SelectedStyle:{
            padding: '1px',
            border: '1px solid #FBBE5D',
            borderRadius:'3px',
            boxShadow: ' 0px 4px 4px rgba(237,177,78,0.25)'
            
        },

        NoSelectedStyle:{
            padding: '1px',
            border: '1px solid white'
        }
    };
}
 
}
</script>

<style>
.switch-component-wrapper{
    display: flex;
    justify-content: center;
    padding-bottom: 0.3rem;
    

}
.switch-wrapper{
    width: 60px;
    display: flex;
    cursor: pointer;
    align-items: center;
    padding: 2px;
    transition: all 0.5s;
    background: #F9F8FB;
    border: 1px solid #EBEBEC;
    border-radius: 10px;
    box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
   
}
.circle{
    background: #EC6550;
    width: 13px;
    height: 13px;
    border-radius: 18px;
    box-shadow: 0px 0px 3px 1px rgba(0,0,0,0.3);
    border: 1px solid #EC6550;

}
.on{
    background: #F9F8FB;
    justify-content: flex-start;
}
.off{
    background: #F9F8FB;
    justify-content: flex-end;
}

.flag-content{
    display: flex;
    width: 60px;
    justify-content: space-between;
    margin-left: 0.5rem;
    padding: 5px;
    
}

.flag{
 font-size: 20px;

}
.switcher-content{
    display: flex;
    flex-direction: column;
    width: 90px;
    background: #FFFFFF;
    border: 1px solid #EBEBEC;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 10px
    

}

</style>