<template>
  <aside class="sidebar" :class="expanded ? 'expanded' : 'collapsed'">
    <div class="side-nav-icon" @click.prevent="expanded = !expanded"><img src="@/assets/images/icons/ico-menu.svg"></div>
    <nav class="side-nav">
      <ul class="side-nav__items">
        <div class="side-nav__logo">
          <router-link :to="$store.state.user.id ? { path: '/' } : { name: 'AccessActivity' }"><img src="@/assets/images/logos/LogoMeaning.svg"></router-link>
        </div>
        <slot>
          <li class="side-nav__item">
            <!-- Home route -->
            <!-- <router-link :to="{ path: '/' }"><img class="side-nav__icon" src="@/assets/images/icons/ico-home--black.svg">{{$t('sidenav.inicio')}}</router-link> -->
            <router-link :to="{ name: 'Activities' }"><img class="side-nav__icon" src="@/assets/images/icons/ico-activities--black.svg">{{$t('sidenav.actividades')}}</router-link>
          </li>
          <!-- admin -->
          <template v-if="$store.state.user.role_id === 1">
            <li class="side-nav__item">
              <router-link :to="{ name: 'ActivityProgressTracker' }"><img class="side-nav__icon" src="@/assets/images/icons/ico-monitoring-activity.svg">{{$t('sidenav.seguimiento')}}</router-link>
            </li>
            <li class="side-nav__item">
              <router-link :to="{ name: 'AssignedActivities' }"><img class="side-nav__icon" src="@/assets/images/icons/ico-assigned-activities.svg">{{$t('sidenav.actividadesasignadas')}}</router-link>
            </li>
            <li class="side-nav__item">
              <router-link :to="{ name: 'Questions' }"><img class="side-nav__icon" src="@/assets/images/icons/ico-questions--black.svg">{{$t('sidenav.bancopreguntas')}}</router-link>
            </li>
            <li class="side-nav__item">
            <!--  <router-link :to="{ name: 'Activities' }"><img class="side-nav__icon" src="@/assets/images/icons/ico-activities--black.svg">{{$t('sidenav.actividades')}}</router-link> -->
            </li>
            <li class="side-nav__item">
              <router-link :to="{ name: 'DownloadData' }"><img class="side-nav__icon" src="@/assets/images/icons/ico-download--black.svg">{{$t('sidenav.descarga')}}</router-link>
            </li>

            <li class="side-nav__item">
              <router-link :to="{ name: 'AdminsList' }"><img class="side-nav__icon" src="@/assets/images/icons/ico-admins--black.svg">{{$t('sidenav.administradores')}}</router-link>
            </li>
            <li class="side-nav__item">
              <router-link :to="{ name: 'TeachersList' }"><img class="side-nav__icon" src="@/assets/images/icons/ico-teacher--black.svg">{{$t('sidenav.profesores')}}</router-link>
            </li>
            <li class="side-nav__item">
              <router-link :to="{ name: 'InvestigatorsList' }"><img class="side-nav__icon" src="@/assets/images/icons/ico-research--black.svg">{{$t('sidenav.investigadores')}}</router-link>
            </li>

            <li class="side-nav__item">
              <router-link :to="{ name: 'MoreInfo' }"><img class="side-nav__icon" src="@/assets/images/icons/info.svg">{{$t('sidenav.masinfo')}}</router-link>
            </li>
            
          </template>
          <!-- profe -->
          <template v-else-if="$store.state.user.role_id === 2">
            <li class="side-nav__item">
            <!--  <router-link :to="{ name: 'Activities' }"><img class="side-nav__icon" src="@/assets/images/icons/ico-activities--black.svg">{{$t('sidenav.actividades')}}</router-link> -->
            </li>
            <li class="side-nav__item">
              <router-link :to="{ name: 'ActivityProgressTracker' }"><img class="side-nav__icon" src="@/assets/images/icons/ico-monitoring-activity.svg">{{$t('sidenav.seguimiento')}}</router-link>
            </li>
            <li class="side-nav__item">
              <router-link :to="{ name: 'Questions' }"><img class="side-nav__icon" src="@/assets/images/icons/ico-questions--black.svg">{{$t('sidenav.bancopreguntas')}}</router-link>
            </li>
            <li class="side-nav__item">
              <router-link :to="{ name: 'DownloadData' }"><img class="side-nav__icon" src="@/assets/images/icons/ico-download--black.svg">{{$t('sidenav.descarga')}}  </router-link>
            </li>
            <li class="side-nav__item">
              <router-link :to="{ name: 'MoreInfo' }"><img class="side-nav__icon" src="@/assets/images/icons/info.svg">{{$t('sidenav.masinfo')}}</router-link>
            </li>
          </template>
          <template v-else-if="$store.state.user.role_id === 3">
            <li class="side-nav__item">
            <!--  <router-link :to="{ name: 'Activities' }"><img class="side-nav__icon" src="@/assets/images/icons/ico-activities--black.svg">{{$t('sidenav.actividades')}}</router-link> -->
            </li>
            <li class="side-nav__item">
              <router-link :to="{ name: 'DownloadData' }"><img class="side-nav__icon" src="@/assets/images/icons/ico-download--black.svg">{{$t('sidenav.descarga')}}  </router-link>
            </li>
          </template>
        </slot>
      </ul>
      
      <div class="side-nav__options">
        <ul class="side-nav__items">
          <div class="navswitch-container">
          <Switch :switchValue="switchValue" @switchValueChanged="switchValue = !switchValue"/>
          </div>
          <li class="side-nav__item">
            <router-link v-if="$store.state.user.id" :to="{ query: { logout: true } }"><img class="side-nav__icon" src="@/assets/images/icons/ico-logout--black.svg">  {{$t('sidenav.logout')}}</router-link>
            <router-link v-else :to="{ name: 'AccessActivity' }"><img class="side-nav__icon" src="@/assets/images/icons/ico-logout--black.svg">  {{$t('sidenav.salir')}}</router-link>
          </li>
        </ul>
      </div>
    </nav>
  </aside>
</template>

<script>
import Switch from '../components/Switch.vue'
export default {
  components: { Switch },
  name: 'SideNav',
  data (){
   return{
     switchValue: true
   }
  },
  props: ['modelValue'],
  emits: ['update:modelValue'],
  computed: {
    expanded: {
      get () { return this.modelValue },
      set (val) { this.$emit('update:modelValue', val) }
    }
  },
  
  
}
</script>

<style>
.navswitch-container{
  display: flex;
  justify-content: center;
  margin-bottom: 1rem;
}
@media(max-width: 428px){
  .side-nav{
    justify-content: normal !important;
  }
  .side-nav__options{
    margin-top: 1rem !important;
  }

}
</style>
