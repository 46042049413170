import { createRouter, createWebHistory } from 'vue-router'
import axios from 'axios'
import store from '@/store'

const routes = [
  {
    path: '/',
    redirect: '/login'
  },
  {
    path: '/login',
    name: 'Login',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "login" */ '@/views/Login'),
    beforeEnter: (to, from, next) => checkNotAuthenticated(to, from, next),
    meta: { noSideNav: true }
  },
  {
    path: '/signup',
    name: 'Signup',
    component: () => import('@/views/Signup'),
    beforeEnter: (to, from, next) => checkNotAuthenticated(to, from, next),
    meta: { noSideNav: true }
  },
  {
    path: '/admin-home',
    name: 'AdminHome',
    component: () => import('@/views/AdminHome'),
    beforeEnter: (to, from, next) => checkAuthenticated(to, from, next)
  },
  {
    path: '/teacher-home',
    name: 'TeacherHome',
    component: () => import('@/views/TeacherHome'),
    beforeEnter: (to, from, next) => checkAuthenticated(to, from, next)
  },
  {
    path: '/activities',
    name: 'Activities',
    component: () => import('@/views/Activities'),
    beforeEnter: (to, from, next) => checkAuthenticated(to, from, next),

  },
  {
    path: '/activities/:activityId',
    name: 'ActivityShow',
    component: () => import('@/views/ActivityShow'),
    beforeEnter: (to, from, next) => checkAuthenticated(to, from, next)
  },
  {
    path: '/answer-activity/:code',
    name: 'AnswerActivity',
    component: () => import('@/views/ActivityShowParents'),
    //beforeEnter: (to, from, next) => checkNotAuthenticated(to, from, next),
    meta: { isTutor: true }
  },

  {
    path: '/activities/:activityId/edit',
    name: 'ActivitiesEdit',
    component: () => import('@/views/ActivitiesEdit'),
    beforeEnter: (to, from, next) => checkAuthenticated(to, from, next)
  },
  {
    path: '/activities-new',
    name: 'ActivitiesNew',
    component: () => import('@/views/ActivitiesNew'),
    beforeEnter: (to, from, next) => checkAuthenticated(to, from, next)
  },
  {
    path: '/admins',
    name: 'AdminsList',
    component: () => import('@/views/AdminsList'),
    beforeEnter: (to, from, next) => checkAuthenticated(to, from, next)
  },
  {
    path: '/teachers',
    name: 'TeachersList',
    component: () => import('@/views/TeachersList'),
    beforeEnter: (to, from, next) => checkAuthenticated(to, from, next)
  },
  {
    path: '/investigators',
    name: 'InvestigatorsList',
    component: () => import('@/views/InvestigatorsList'),
    beforeEnter: (to, from, next) => checkAuthenticated(to, from, next)
  },
  {
    path: '/teachers/:userId',
    name: 'TeachersShow',
    component: () => import('@/views/TeachersShow'),
    beforeEnter: (to, from, next) => checkAuthenticated(to, from, next)
  },
  {
    path: '/investigators/:userId',
    name: 'InvestigatorsShow',
    component: () => import('@/views/InvestigatorsShow'),
    beforeEnter: (to, from, next) => checkAuthenticated(to, from, next)
  },
  {
    path: '/admins/invite',
    name: 'AdminsInvite',
    component: () => import('@/views/AdminsInvite'),
    beforeEnter: (to, from, next) => checkAuthenticated(to, from, next)
  },
  {
    path: '/admins/:userId/edit',
    name: 'AdminsEdit',
    component: () => import('@/views/AdminsEdit'),
    beforeEnter: (to, from, next) => checkAuthenticated(to, from, next)
  },
  {
    path: '/invites/:token',
    name: 'InvitesShow',
    component: () => import('@/views/InvitesShow'),
    beforeEnter: (to, from, next) => checkNotAuthenticated(to, from, next),
    meta: { noSideNav: true }
  },
  {
    path: '/forgot-password',
    name: 'RequestPasswordRecovery',
    component: () => import('@/views/RequestPasswordRecovery'),
    beforeEnter: (to, from, next) => checkNotAuthenticated(to, from, next),
    meta: { noSideNav: true }
  },
  {
    path: '/recover-password/:token',
    name: 'RecoverPassword',
    component: () => import('@/views/RecoverPassword'),
    beforeEnter: (to, from, next) => checkNotAuthenticated(to, from, next),
    meta: { noSideNav: true }
  },
  {
    path: '/question-new',
    name: 'QuestionNew',
    component: () => import('@/views/QuestionNew'),
    beforeEnter: (to, from, next) => checkAuthenticated(to, from, next)
  },
  {
    path: '/questions',
    name: 'Questions',
    component: () => import('@/views/QuestionsList'),
    beforeEnter: (to, from, next) => checkAuthenticated(to, from, next)
  },
  {
    path: '/download-data',
    name: 'DownloadData',
    component: () => import('@/views/DownloadData'),
    beforeEnter: (to, from, next) => checkAuthenticated(to, from, next)
  },
  {
    path: '/more-info',
    name: 'MoreInfo',
    component: () => import('@/views/MoreInfo'),
    beforeEnter: (to, from, next) => checkAuthenticated(to, from, next)
  },
  {
    path: '/access-activity',
    name: 'AccessActivity',
    component: () => import('@/views/AccessActivity'),
    //beforeEnter: (to, from, next) => checkNotAuthenticated(to, from, next),
    meta: { noSideNav: true }
  },
  {
    path: '/:pathMatch(.*)*',
    redirect: '/'
  },
  {
    path: '/activity-progress-tracker',
    name: 'ActivityProgressTracker',
    component: () => import('@/views/ActivityProgressTracker'),
    beforeEnter: (to, from, next) => checkAuthenticated(to, from, next)
  },
  {
    path: '/assigned-activities',
    name: 'AssignedActivities',
    component: () => import('@/views/AssignedActivities'),
    beforeEnter: (to, from, next) => checkAuthenticated(to, from, next)
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach(async (to, from, next) => {
  // console.log(`Navigating from ${from.name} to ${to.name}`)
  if (to.query.logout) {
    store.commit('setUserData', {})
    return next('/')
  }

  if (store.state.mediaQuery != null && store.state.user.id) {
    let shouldExpand = ['xl', 'xxl'].includes(store.state.mediaQuery)

    if (store.state.user.ui_data['EXPANDED-SIDEBAR'] !== shouldExpand) {
      let res = await axios.patch(`users/${store.state.user.id}`, { ui_data: { 'EXPANDED-SIDEBAR': shouldExpand } })
      store.commit('setUserData', res.data.user)
    }
  }
  next()
})

const checkAuthenticated = (to, from, next) => {
  if (store.state.user.id) {
    next()
  } else {
    next('/')
  }
}

const checkNotAuthenticated = (to, from, next) => {
  if (!store.state.user.id) {
    next()
  } else if (store.state.user.role_id === 1) {
    next({ name: 'Activities' })
  } else if (store.state.user.role_id === 2) {
    next({ name: 'Activities' })
  } else {
    next({ name: 'DownloadData' })
  }
}

export default router
