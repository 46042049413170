export default {
  "login": {
    "iniciaSesion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inicia sesión"])},
    "ingresadatos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingresa tus datos de registro para acceder"])},
    "correo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Correo electrónico"])},
    "contra": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contraseña"])},
    "iniciarSesion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Iniciar sesión"])},
    "registrate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Regístrate"])},
    "recuperarcontra": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿Olvidaste tu contraseña?"])},
    "alertmsg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Correo electrónico o contraseña incorrectos"])}
  },
  "singup": {
    "registrate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Regístrate"])},
    "ingresadatos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingresa tus datos para completar el registro"])},
    "nombre": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre"])},
    "apellido": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apellido"])},
    "telefono": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teléfono"])},
    "direccion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dirección"])},
    "sexo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sexo"])},
    "correo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Correo electrónico"])},
    "contra": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contraseña"])},
    "confirmcontra": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmar contraseña"])},
    "selecperfil": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selección de perfil"])},
    "fechanacimiento": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fecha de nacimiento"])},
    "curso": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Curso"])},
    "colegio1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Colegio 1"])},
    "colegio2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Colegio 2"])},
    "crearcuenta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crear cuenta"])},
    "iniciaSesion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inicia sesión"])},
    "profesor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profesor"])},
    "investigador": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Investigador"])}
  },
  "forgotpassword": {
    "recuperarcontra": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recuperar contraseña"])},
    "ingrescorreop1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingresa el correo electrónico registrado en"])},
    "ingresacorreop2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["+MEANING"])},
    "ingresacorreop3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" para enviar las instrucciones de recuperación"])},
    "correo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Correo electrónico"])},
    "enviar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enviar instrucciones"])},
    "alertmsg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Solicita una nueva contraseña a aev", "@", "meaning.cl y te la enviaremos lo antes posible."])}
  },
  "recoverpassword": {
    "nuevacontratitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nueva contraseña"])},
    "ingresanuevacontra": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingresa una nueva contraseña para tu cuenta"])},
    "nuevacontra": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nueva contraseña"])},
    "confirmarnuevacontra": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Confirmar nueva contraseña"])},
    "cambiarcontra": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cambiar contraseña"])},
    "alertchangepass": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tu contraseña ha sido cambiada exitosamente"])},
    "linkcaducado": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El enlace para recuperar contraseña ha caducado"])}
  },
  "sidenav": {
    "inicio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inicio"])},
    "administradores": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Administradores"])},
    "profesores": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profesores"])},
    "investigadores": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Investigadores"])},
    "seguimiento": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seguimiento de actividades"])},
    "actividadesasignadas": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actividades asignadas"])},
    "bancopreguntas": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Banco de Preguntas"])},
    "actividades": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actividades"])},
    "descarga": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Descarga de respuestas"])},
    "masinfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Más Información"])},
    "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cerrar Sesión"])},
    "salir": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salir"])}
  },
  "adminhome": {
    "saludo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hola"])},
    "bienvenida": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bienvenido al centro de control"])}
  },
  "adminlist": {
    "breadcrumb": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Administradores"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Administradores"])},
    "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agrega o gestiona los administradores de la plataforma"])},
    "btnoptions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opciones"])},
    "linkedit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Editar administrador"])},
    "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eliminar administrador"])},
    "pendinginvitation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invitaciones pendientes"])},
    "resendinvitation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reenviar invitación"])},
    "deleteinvitation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eliminar invitación"])},
    "nodelete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Este usuario no puede ser eliminado"])},
    "successmsg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invitación reenviada exitosamente"])},
    "confirmmsg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿Estás seguro de que quieres eliminar esta invitación?"])},
    "deleteuser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿Estás seguro de que quieres eliminar a este usuario?"])}
  },
  "adminedit": {
    "breadcrumb": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Administradores"])},
    "breadcrumbnavitem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Editar"])},
    "titleedit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Editar"])},
    "labelname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre"])},
    "labellastname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apellido"])},
    "btnupdate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actualizar administrador"])},
    "successmsg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Administrador actualizado"])}
  },
  "admininvite": {
    "breadcrumb": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Administradores"])},
    "breadcrumbnavitem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invitar"])},
    "titleedit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invitar administrador"])},
    "labelemail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Correo electrónico"])},
    "btninvite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invitar administrador"])},
    "successmsg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Administrador invitado"])}
  },
  "teacherlist": {
    "breadcrumb": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profesores"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profesores"])},
    "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gestiona los profesores de la plataforma"])},
    "btnoptions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opciones"])},
    "linkedit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ver profesor"])},
    "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eliminar profesor"])},
    "disablesprofesor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profesores deshabilitados"])},
    "rehabilitarprofesor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rehabilitar profesor"])},
    "eliminaralert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿Estás seguro de que quieres eliminar a este usuario?"])},
    "rehabilitaralert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿Estás seguro de que quieres rehabilitar a este usuario?"])}
  },
  "teachershow": {
    "breadcrumb": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profesores"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profesor"])},
    "info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Información"])},
    "correo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Correo electrónico"])},
    "nombre": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre"])},
    "sexo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sexo"])},
    "fechanacimiento": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fecha de nacimiento"])},
    "telefono": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teléfono"])},
    "direccion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dirección"])},
    "curso": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Curso"])},
    "colegio1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Colegio 1"])},
    "colegio2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Colegio 2"])}
  },
  "researcherlist": {
    "breadcrumb": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Investigadores"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Investigadores"])},
    "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gestiona los investigadores de la plataforma "])},
    "btnoptions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opciones"])},
    "linkedit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ver investigador"])},
    "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eliminar investigador"])},
    "disablesprofesor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["investigadores deshabilitados"])},
    "rehabilitarprofesor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Habilitar investigador"])},
    "eliminaralert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿Estás seguro de que quieres eliminar a este usuario?"])},
    "rehabilitaralert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿Estás seguro de que quieres rehabilitar a este usuario?"])}
  },
  "researchershow": {
    "breadcrumb": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Investigadores"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Investigador"])},
    "info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Información"])},
    "correo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Correo electrónico"])},
    "nombre": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre"])},
    "sexo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sexo"])}
  },
  "questionsbank": {
    "breadcrumb": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Banco de preguntas"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Banco de preguntas"])},
    "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lista de preguntas disponibles para la creación de actividades "])},
    "btndelete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eliminar"])},
    "deletedesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Estás a punto de eliminar esta pregunta"])},
    "questiondelete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿Estás seguro que deseas continuar?"])},
    "confirmdelete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sí, eliminar"])},
    "canceldelete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancelar"])}
  },
  "newquestion": {
    "breadcrumb": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Banco de preguntas"])},
    "breadcrumb2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nueva pregunta"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NUEVA PREGUNTA"])},
    "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Completa la siguiente información y crea una nueva pregunta"])},
    "btnfinish": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crear pregunta"])},
    "alertmsg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tu pregunta ha sido creada exitosamente"])}
  },
  "activities": {
    "breadcrumb": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actividades"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ACTIVIDADES"])},
    "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Encuentra todas las actividades creadas en"])},
    "btnpublic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actividades Públicas"])},
    "btnprivate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actividades Privadas"])},
    "btnassigned": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actividades Asignadas"])},
    "btndefault": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actividades predefinidas"])}
  },
  "activityshow": {
    "nivel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NIVEL"])},
    "asignatura": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ASIGNATURA"])},
    "eje": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["EJE"])},
    "por": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["POR"])},
    "actividades": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actividades"])},
    "preguntas": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preguntas"])},
    "sendresp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enviar respuestas"])},
    "finishacti": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terminar actividad"])},
    "aboutacti": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sobre la actividad"])},
    "enlace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enlace"])},
    "btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opciones"])},
    "deactactivi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Desactivar actividad"])},
    "editactivi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Editar actividad"])},
    "deltactivi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eliminar actividad"])},
    "recursosPrint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aquí tienes material imprimible para tu clase"])},
    "recursosRecom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guía docente"])},
    "enlaceApod": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enlace a material para el apoderado"])},
    "docenteinteractiva": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guía docente interactiva"])},
    "deletedesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Estás a punto de eliminar esta actividad y todos sus códigos de acceso"])},
    "questiondel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿Estás seguro que deseas continuar?"])},
    "confirmdelete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sí, eliminar"])},
    "canceldelete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancelar"])},
    "respenviadas": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Respuestas enviadas"])},
    "respdesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tus respuestas han sido enviadas"])},
    "respconfirmsend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¡Felicitaciones! tu actividad ya se encuentra completa y tus respuestas han sido enviadas correctamente."])},
    "salir": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salir"])},
    "continueactiv": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continuar en la actividad"])},
    "deactivateactiv": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Desactivar"])},
    "deactivateactivdesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Estás a punto de desactivar esta actividad"])},
    "deactivateactivdescquest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿Estás seguro que deseas continuar?"])},
    "deactivateconfirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sí, desactivar"])},
    "fullscreendesktopone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Para una mejor experiencia selecciona "])},
    "fullscreendesktoptwo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Luego selecciona "])},
    "fullscreenphoneone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Para una mejor experiencia selecciona "])},
    "objetivosDeAprendizaje": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Objetivo(s) de aprendizaje"])},
    "objetivoDeDesarrolloSocioemocional": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Objetivo(s) de desarrollo socioemocional"])},
    "activacion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activación"])},
    "desde": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Desde el día"])},
    "hasta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hasta el día"])},
    "ilimitada": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ilimitada"])},
    "editarActivacion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Editar activación"])},
    "editarActivacionDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Está a punto de editar la activación. Para esto, primero seleccione su tiempo de activación"])},
    "activityCompletedButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terminé la actividad"])},
    "duplicarActividad": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Duplicar actividad"])},
    "successmsg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La actividad ha sido duplicada"])},
    "hazclickaqui": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Haz click aquí"])},
    "verPdf": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ver pdf"])}
  },
  "activitiesedit": {
    "breadcrumb": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actividades"])},
    "breadcrumbnavitem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Editar actividad"])},
    "titleedit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["EDITAR ACTIVIDAD"])},
    "subtitleedit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifica tu actividad"])},
    "btnupdate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actualizar actividad"])},
    "successmsg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actividad modificada"])}
  },
  "activitiesnew": {
    "breadcrumb": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actividades"])},
    "breadcrumbnavitem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nueva actividad"])},
    "titlecreate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NUEVA ACTIVIDAD"])},
    "subtitlecreate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Completa la siguiente información y crea una nueva actividad"])},
    "btncreate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crear actividad"])},
    "successmsg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actividad creada"])}
  },
  "accessactivity": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bienvenido"])},
    "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Para acceder a la actividad ingresa tu nombre y el código de la actividad"])},
    "activityname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre"])},
    "activitycode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Código"])},
    "enteractivity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingresar a la actividad"])},
    "codnodis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Código no disponible"])},
    "caducatecode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El código ingresado ha caducado o ya no se encuentra disponible."])},
    "entendido": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entiendo"])},
    "entername": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Solo debes ingresar tu nombre para ir a la actividad"])}
  },
  "downloaddata": {
    "breadcrumb": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Descargar respuestas"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Descarga de respuestas"])},
    "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Descarga el listado completo de respuestas asociadas a las preguntas utilizadas en las actividades. Este proceso podría tardar dependiendo de la cantidad de datos almacenados."])},
    "btndownload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DESCARGAR RESPUESTAS"])},
    "tableStatement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En el archivo descargado se encuentran las siguientes columnas: "])},
    "column": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Columna"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Descripción"])},
    "question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pregunta"])},
    "answer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Respuesta"])},
    "answerType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipo de respuesta"])},
    "dateofanswer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fecha de respuesta"])},
    "activity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actividad"])},
    "activityID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID de la actividad"])},
    "eventCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Código de evento"])},
    "course": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Curso"])},
    "axis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eje curricular"])},
    "professorActivity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profesor (a) Actividad"])},
    "teacherId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID Profesor"])},
    "school1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Colegio 1 Profesor(a)"])},
    "school2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Colegio 2 Profesor(a)"])},
    "authorQuestion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autor pregunta"])},
    "descriptionQuestion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pregunta respondida por el alumno."])},
    "descriptionAnswer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Respuesta entregada por el alumno."])},
    "descriptionAnswerType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Puede ser “Abierta”, “Selección única” o “Selección múltiple”."])},
    "descriptionDateofanswer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fecha y hora en la que el alumno entregó la respuesta."])},
    "descriptionActivity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre de la actividad asignada."])},
    "descriptionActivityID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identificador numérico único de la actividad."])},
    "descriptionEventCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Código del evento de la actividad, este código es único."])},
    "descriptionCourse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Curso al que se le asignó la actividad."])},
    "descriptionAxis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contenidos tratados en la actividad."])},
    "descriptionProfessorActivity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre del profesor que creó la actividad."])},
    "descriptionTeacherId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identificador numérico único del profesor que creó la actividad."])},
    "descriptionSchool1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre del colegio al que pertenece el profesor que creó la actividad."])},
    "descriptionSchool2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre del colegio número dos al que pertenece el profesor que creó la actividad. Si este campo se encuentra vacío se debe a que el profesor no presta servicios a un segundo colegio."])},
    "descriptionAuthorQuestion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre del profesor que creó la pregunta."])}
  },
  "activitiesform": {
    "curso": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["curso"])},
    "asignatura": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["asignatura"])},
    "eje": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["eje"])},
    "idioma": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Idioma"])},
    "visibilityactiv": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visibilidad de la actividad"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Título"])},
    "Instruccion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Instrucción"])},
    "caption1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Añade las instrucciones a tu actividad. Podrás dar formato a ellas en el siguiente cuadro."])},
    "caption2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recuerda que el peso máximo para las imágenes es de 5mb."])},
    "enlacePrint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enlace a material imprimible"])},
    "enlaceRecom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enlace a guía docente descargable"])},
    "enlaceApod": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enlace a material para el apoderado"])},
    "enlaceInteractiva": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enlace a guía docente interactiva"])},
    "questionaddactivity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preguntas añadidas a la actividad"])},
    "addquestion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Añadir pregunta"])},
    "questionbank": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Banco de preguntas"])},
    "btnadd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agregar"])},
    "btncontinuar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continuar"])},
    "addslide": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agregar slide"])},
    "herramientacreacion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Herramienta para crear actividad"])},
    "manual": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manual"])},
    "genially": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Genially"])},
    "linkgenially": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingrese el link de Genially"])},
    "objetivosDeAprendizaje": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Objetivo(s) de aprendizaje"])},
    "objetivoDeDesarrolloSocioemocional": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Objetivo(s) de desarrollo socioemocional"])},
    "actividadDefault": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actividad predefinida"])}
  },
  "activitycard": {
    "activa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activa"])},
    "info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compartir"])},
    "activar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compartir"])},
    "ver": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ver"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Estás a punto de activar esta actividad. Para esto primero selecciona su tiempo de habilitación"])},
    "timehabili": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tiempo de habilitación"])},
    "desde": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Desde el día"])},
    "hasta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hasta el día"])},
    "cerrar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cerrar"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compartir"])},
    "infodisp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tu actividad se encuentra disponible de forma limitada entre los días"])},
    "al": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["al"])},
    "unlimiteddisp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tu actividad se encuentra disponible de forma ilimitada"])},
    "copylink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copia el siguiente enlace para compartir esta actividad"])},
    "copylinksecond": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" O copia el siguiente enlace para acceder a cualquier actividad"])},
    "uniccode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comparte el siguiente código único para ingresar a esta actividad utilizando el enlace anterior"])},
    "viewactivity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ver actividad"])},
    "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enlace:"])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Código:"])},
    "assignment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asignación"])},
    "activityassignment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asignación de actividad"])},
    "assign": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Para asignar un profesor a la actividad su casilla debe estar marcada"])},
    "unassign": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Para desasignar un profesor a la actividad su casilla debe estar desmarcada"])},
    "updateassignment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actualizar asignación"])},
    "selectall": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleccionar Todos"])}
  },
  "questionform": {
    "encabezado": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Encabezado de la pregunta"])},
    "responsetype": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipo de respuesta"])},
    "Opcion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opción"])}
  },
  "invitesshow": {
    "register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Regístrate"])},
    "enterdata": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingresa tus datos para completar el registro"])},
    "correo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Correo electrónico"])},
    "nombre": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre"])},
    "apellido": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apellido"])},
    "contra": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contraseña"])},
    "confirmcontra": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmar contraseña"])},
    "registrar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registrar"])},
    "alertmsg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Esta invitación ha caducado"])}
  },
  "teacherhome": {
    "saludo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hola"])},
    "bienvenida": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bienvenido a"])}
  },
  "moreinfo": {
    "masinfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Para más Información visita"])}
  },
  "activityprogresstracker": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seguimiento de Actividades"])},
    "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Encuentra las actividades que has compartido y la cantidad de alumnos que las han completado."])},
    "breadcrumb": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seguimiento de Actividades"])},
    "titleActivity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Título de la actividad"])},
    "idActivity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identificador de la actividad"])},
    "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fecha de activación"])},
    "isActive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Estado"])},
    "number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cantidad de alumnos que han completado la actividad"])},
    "searchOptions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opciones de búsqueda"])},
    "respuestas": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cantidad de respuestas"])},
    "Total_responses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numero total de respuestas"])}
  },
  "Assignedactivities": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actividades Asignadas"])},
    "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En esta sección, puedes asignar profesores de forma masiva a las actividades que elijas utilizando la carga de un archivo CSV. Además, encontrarás un listado completo de actividades acompañadas por sus respectivos profesores asignados."])},
    "breadcrumb": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actividades Asignadas"])},
    "titleActivity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Título de la actividad"])},
    "idActivity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identificador de la actividad"])},
    "teacher": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profesores asignados"])},
    "searchOptions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opciones de búsqueda"])},
    "downloadTeachers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Descargar lista de Profesores"])},
    "downloadActivities": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Descargar lista de Actividades"])},
    "downloadTemplate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Descargar Plantilla"])},
    "uploadCsv": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asignar actividades vía archivo CSV"])},
    "successMsg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Todas las asignaciones se realizaron exitosamente."])},
    "downloadErrors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["aquí"])},
    "downloadErrorsMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No se pudieron completar todas las asignaciones. Para obtener más información, haz clic"])}
  },
  "activityprogresstrackerCard": {
    "activa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activa"])},
    "inactiva": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inactiva"])}
  }
}