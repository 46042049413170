import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'
import VueCookies from 'vue3-cookies'
import CKEditor from '@ckeditor/ckeditor5-vue'
import VueMq from 'vue3-mq'
import i18n from './i18n'
import FlagIcon from 'vue-flag-icon'
import vue3GoogleLogin from 'vue3-google-login'


// Global axios configurations
axios.defaults.baseURL = `${process.env.VUE_APP_API_BASE_URL || window.location.origin}/api`
axios.defaults.headers.common['Accept'] = 'application/json; charset=utf-8'

axios.interceptors.request.use(req => {
  if (req.baseURL) {
    req.url = '/' + req.url
  }
  return req
})

axios.interceptors.response.use(res => {
  // Captures user data returned on every authenticated request
  if (res.data._user) {
    store.commit('setUserData', res.data._user)
    // i18n.locale = res.data._user.ui_data['LOCALE'] || i18n.locale
    delete res.data._user
  }
  return res
}, (err) => {
  console.log(err)
  return Promise.reject(err)
})

const app = createApp(App)
  .use(FlagIcon)
  .use(i18n)
  .use(VueCookies, { expireTimes: 0 })
  .use(CKEditor)
  .use(VueMq, {
    'sm': 576,
    'md': 769,
    'lg': 900,
    'xl': 1199,
    'xxl': 1440
  })

app.provide('$axios', axios)

app.use(vue3GoogleLogin, {
  clientId: process.env.VUE_APP_CLIENT_ID_GOOGLE,
})

// Global methods
app.config.globalProperties.$utils = {
  extractInitials: ({ first_name = '', last_name = '' }) => {
    return `${first_name[0] || ''}${last_name[0] || ''}`.toUpperCase()
  },
  cloneObj(obj) {
    let copy
    if (obj === null || obj === undefined || obj instanceof File || typeof obj !== 'object') return obj
    if (obj instanceof Array) {
      copy = []
      obj.forEach(element => copy.push(app.config.globalProperties.$utils.cloneObj(element)))
      return copy
    }
    if (obj instanceof Date) {
      copy = new Date()
      copy.setTime(obj.getTime())
      return copy
    }
    if (obj instanceof Object) {
      copy = {}
      Object.keys(obj).forEach(key => {
        copy[key] = app.config.globalProperties.$utils.cloneObj(obj[key])
      })
      return copy
    }
    throw new Error(`${typeof obj} is not supported`)
  }
}
app.provide('$utils', app.config.globalProperties.$utils)

app.config.globalProperties.$axios = axios
const authToken = app.config.globalProperties.$cookies.get('jwt')
if (authToken) {
  axios.defaults.headers.common['Authorization'] = authToken
  // app.config.globalProperties.$axios.get('init').then(() => {
  axios.get('init').then(() => {
    app.use(store).use(router)
    app.mount('#app')
  }).catch(error => {
    console.log(error)
    app.config.globalProperties.$cookies.remove('jwt')
    delete axios.defaults.headers.common['Authorization']

    app.use(store).use(router)
    app.mount('#app')
  })
} else {
  app.use(store).use(router)
  app.mount('#app')
}

