import { createStore } from 'vuex'

export default createStore({
  state: {
    user: {},
    mediaQuery: null,
    tutorSideNavExpanded: false
  },
  mutations: {
    setUserData (state, value) {
      state.user = value || {}
    },
    setMediaQuery (state, value) {
      state.mediaQuery = value || null
    },
    setTutorSideNavExpanded (state, value) {
      state.tutorSideNavExpanded = value
    }
  },
  actions: {
  },
  modules: {
  }
})
